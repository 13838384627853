import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAddressCard,
  faAlignJustify,
  faAnchor,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArchive,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowsRotate,
  faArrowUp,
  faAsterisk,
  faBackward,
  faBan,
  faBars,
  faBell,
  faBook,
  faBookmark,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarDay,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartArea,
  faChartSimple,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronCircleUp,
  faChevronDown,
  faChevronRight,
  faChild,
  faCircle,
  faCircleExclamation,
  faCircleInfo,
  faCircleNotch,
  faCircleUser,
  faCircleXmark,
  faClipboardCheck,
  faClipboardUser,
  faCloudArrowUp,
  faCoffee,
  faCog,
  faCogs,
  faComment,
  faCommentDots,
  faComments,
  faCompress,
  faCreditCard,
  faDatabase,
  faEdit,
  faEllipsis,
  faEnvelope,
  faEraser,
  faExchangeAlt,
  faExclamation,
  faExpand,
  faEye,
  faEyeSlash,
  faFile,
  faFileArrowUp,
  faFileCircleCheck,
  faFileCircleQuestion,
  faFileCircleXmark,
  faFileDownload,
  faFileExcel,
  faFileExport,
  faFilePdf,
  faFileUpload,
  faFilter,
  faFolder,
  faHeart,
  faHeartCircleCheck,
  faHeartCircleExclamation,
  faHome,
  faHotel,
  faImage,
  faInfoCircle,
  faKey,
  faListCheck,
  faLocationArrow,
  faLongArrowAltUp,
  faMailBulk,
  faMarker,
  faMaximize,
  faMicroscope,
  faMinimize,
  faMinus,
  faMinusCircle,
  faPaperclip,
  faPencilAlt,
  faPenToSquare,
  faPeopleArrows,
  faPlane,
  faPlaneDeparture,
  faPlus,
  faPlusCircle,
  faPrint,
  faRightFromBracket,
  faRotateRight,
  faSave,
  faSearch,
  faShip,
  faSignInAlt,
  faSignOutAlt,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSpinner,
  faSquare,
  faSquarePollVertical,
  faStepBackward,
  faStepForward,
  faSync,
  faSyringe,
  faTableList,
  faTachometerAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUpload,
  faUser,
  faUserCheck,
  faUserFriends,
  faUserLock,
  faUserPlus,
  faUsersBetweenLines,
  faUserTie,
  faUserTimes,
  faVial,
  faWindowClose,
  faWrench,
  faX,
} from "@fortawesome/free-solid-svg-icons";

export default () => {
  library.add(
    faCoffee,
    faChild,
    faCircle,
    faArchive,
    faComment,
    faSignInAlt,
    faEyeSlash,
    faEye,
    faStepBackward,
    faStepForward,
    faHeart,
    faVial,
    faMicroscope,
    faBars,
    faUserFriends,
    faBookmark,
    faCalendarDay,
    faCalendarAlt,
    faHome,
    faAngleDown,
    faCogs,
    faChartArea,
    faEdit,
    faBookmark,
    faFileDownload,
    faFileUpload,
    faFile,
    faUndo,
    faPrint,
    faBook,
    faTimes,
    faSignOutAlt,
    faArrowsAltH,
    faWindowClose,
    faArrowsAltH,
    faSave,
    faBan,
    faSearch,
    faPlus,
    faCheck,
    faHotel,
    faExchangeAlt,
    faTrash,
    faArrowRight,
    faExclamation,
    faComments,
    faCommentDots,
    faCaretDown,
    faCaretUp,
    faUserPlus,
    faPaperclip,
    faTachometerAlt,
    faAsterisk,
    faMailBulk,
    faBell,
    faEnvelope,
    faUserTie,
    faSpinner,
    faFilter,
    faUpload,
    faUserTimes,
    faPlusCircle,
    faChevronCircleUp,
    faSync,
    faCheckCircle,
    faKey,
    faUserLock,
    faSyringe,
    faCircleNotch,
    faLocationArrow,
    faShip,
    faSquare,
    faThumbsUp,
    faThumbsDown,
    faEraser,
    faArrowUp,
    faArrowDown,
    faMinusCircle,
    faTimesCircle,
    faCog,
    faBackward,
    faArrowLeft,
    faArrowCircleUp,
    faCaretRight,
    faWrench,
    faFolder,
    faUser,
    faChevronRight,
    faAnchor,
    faChevronDown,
    faLongArrowAltUp,
    faMinus,
    faPencilAlt,
    faMarker,
    faDatabase,
    faCheckDouble,
    faSortAmountUpAlt,
    faSortAmountDownAlt,
    faAlignJustify,
    faEllipsis,
    faAngleLeft,
    faAnglesLeft,
    faAngleRight,
    faAnglesRight,
    faPenToSquare,
    faListCheck,
    faClipboardCheck,
    faFileCircleCheck,
    faFileCircleXmark,
    faFileCircleQuestion,
    faExpand,
    faCompress,
    faCalendar,
    faPlane,
    faPlaneDeparture,
    faCalculator,
    faCircleInfo,
    faAddressCard,
    faHeartCircleExclamation,
    faHeartCircleCheck,
    faCircleXmark,
    faRightFromBracket,
    faPeopleArrows,
    faUserCheck,
    faTableList,
    faCircleUser,
    faUsersBetweenLines,
    faUserCheck,
    faFilePdf,
    faFileExcel,
    faImage,
    faMaximize,
    faMinimize,
    faX,
    faCloudArrowUp,
    faCircleExclamation,
    faChartSimple,
    faMaximize,
    faMinimize,
    faClipboardUser,
    faFileArrowUp,
    faFileExport,
    faArrowsRotate,
    faCreditCard,
    faSquarePollVertical,
    faInfoCircle,
    faRotateRight
  );
};
