import {createApp} from 'vue';
import {createPinia} from 'pinia';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import '../public/css/custom.css';
import "@vueform/multiselect/themes/default.css";
import $ from 'jquery';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import iconConfig from "@/config/icons.config";
import axiosConfig from "@/config/axios.config";
import componentConfig from "@/config/component.config";
import Popper from 'popper.js';


const app = createApp(App);
app.use(createPinia());
DataTable.use(DataTablesCore);
iconConfig();
axiosConfig();
app.use(router);
componentConfig(app);
window.$ = $;
window.Popper = Popper;
app.mount('#app');
